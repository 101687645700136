import { template as template_e3ceda1ba08445aba900ff0e7b127a5d } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_e3ceda1ba08445aba900ff0e7b127a5d(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_e3ceda1ba08445aba900ff0e7b127a5d(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_e3ceda1ba08445aba900ff0e7b127a5d(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
