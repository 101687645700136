import { template as template_c1d99c3d341445e88287a5e49ae72093 } from "@ember/template-compiler";
const WelcomeHeader = template_c1d99c3d341445e88287a5e49ae72093(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
