import { template as template_32446a2e5b864e1f9e7daf30a90e4d88 } from "@ember/template-compiler";
const FKLabel = template_32446a2e5b864e1f9e7daf30a90e4d88(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
